module.exports = {
  list: [
    {
      id: 17,
      d:
        "M437.936 783.465l8.813-1.35 16.347-8.813 4.122-5.473 67.377 164.533-71.499 20.4-61.265-149.538 9.524-4.763 8.813-2.699 2.772-4.763z",
    },
    {
      id: 16,
      d:
        "M534.595 932.362L467.218 767.83l6.752-8.104 4.761-6.823 10.236-7.462 14.285-17.698 8.174-10.873 8.883-6.752 85.785 201.137z",
    },
    {
      id: 15,
      d:
        "M676.245 890.857L592.52 688.37l-23.17 7.463-25.23 5.4-23.811 8.886 85.786 201.136z",
    },
    {
      id: 2,
      d:
        "M1524.715 264.916l-44.207-103.98 32.623-24.45-2.7-.711-4.764-7.462-4.05-24.45-25.942-35.394-6.753-10.873-10.944-12.865-26.51-22.46-9.524-6.111-8.174 1.35-10.945 6.823-12.935 12.224-12.226 15.637-7.533 29.21 89.908 210.022z",
    },
    {
      id: 6,
      d:
        "M1209.435 404.93l65.387-31.983-85.145-184.862-5.4 4.762-13.648 13.646-12.224 21.038-5.472 21.748-11.586 20.398z",
    },
    {
      id: 8,
      d:
        "M1086.905 470.175l20.398-14.926 35.395-17.697-66.737-140.014h-6.114l-8.883-.711-6.751 3.412-3.412 4.762-7.536 10.235-16.345 16.986-8.814 6.752z",
    },
    {
      id: 10,
      d:
        "M986.763 590.502l23.17-10.874 17.697-53.02-100.07-157.002-2.774 1.99-10.234 10.235-14.925 24.45-3.413 5.471-9.522 18.338-7.534 10.874z",
    },
    {
      id: 23,
      d:
        "M1455.988 521.135l-70.86 59.843-106.254-190.974 61.336-30.633 21.109-8.173z",
    },
    {
      id: 24,
      d:
        "M1385.127 580.978l-12.225 10.164-61.974 61.833-113.718-223.596 81.663-39.374z",
    },
    {
      id: 20,
      d:
        "M1548.597 264.205l45.628-17.626 48.33 118.906-45.629 38.095-68.799-131.202z",
    },
    {
      id: 12,
      d:
        "M834.241 504.858L928.91 638.05l-51.102 40.796 8.172 43.497 10.234 50.25 2.062 18.406-79.673 35.324L715.76 610.188l4.762-2.06 23.17-14.286 4.051-6.113 10.875-17.625 8.173-3.412 29.283-18.336 15.707-14.998z",
    },
    {
      id: 4,
      d:
        "M1396.712 317.936l-60.625-138.664-11.585 8.814-22.459 4.122-20.397-.71-19.049-4.053 28.572 72.07 11.585 16.986 33.333 66.596z",
    },
    {
      id: 14,
      d:
        "M592.52 688.37l12.225-4.123 14.996-7.462 4.762-3.412 6.112-12.225-1.35-3.412v-6.752l4.052-5.472 5.472-3.413 8.174-7.46 96.73 225.656-67.448 30.561z",
    },
    {
      id: 13,
      d:
        "M646.962 634.639l5.473-1.351 12.935.71h10.163l6.184-2.06 6.112-5.472 16.347-8.102 8.173-6.825 3.412-1.35 102.842 216.135-74.91 33.97z",
    },
    {
      id: 18,
      d:
        "M401.831 803.224l61.265 149.538-81.735 23.738-59.914-148.827 4.761-.711 10.946-4.761 8.174-3.341 12.936-2.062 25.87-7.462z",
    },
    {
      id: 37,
      d:
        "M844.474 922.13l8.814 28.57 12.296 86.993-118.55 46.198-40.158-110.09z",
    },
    {
      id: 34,
      d: "M856.7 905.854l147.76-63.255-.64 93.105-128.713 41.506-4.761-29.21z",
    },
    {
      id: 27,
      d:
        "M917.325 801.163l-4.761-31.273-15.71-84.293 48.402-38.735 40.868-37.384 5.402-1.99 63.325 61.123.712 81.592-50.391-.712z",
    },
    {
      id: 26,
      d:
        "M991.525 607.489l32.692-15.636 18.41-57.143 17.697-23.1 55.863-42.145 100.07 239.943-60.624.71-25.871-8.174-19.76-19.685-55.151-13.647z",
    },
    {
      id: 21,
      d: "M1532.25 280.551l64.677 123.028-70.15 58.422-81.023-146.127z",
    },
    {
      id: 31,
      d:
        "M1100.48 843.309l127.363.64-.001 191.684-151.172-15.635 18.031-25.326 5.138-19.06.001-40.546z",
    },
    {
      id: 29,
      d:
        "M1004.46 842.6l.711-93.108 96.02 2.062 125.942 2.06.709 90.335-127.361-.64z",
    },
    {
      id: 28,
      d:
        "M1101.19 751.554l-45.627-1.351-.712-81.591 55.153 13.647 19.758 19.686 25.87 8.173 60.626-.71 10.875 26.509v17.698z",
    },
    {
      id: 33,
      d: "M875.107 977.21l128.713-41.507v81.594h-38.165l-82.375 14.925z",
    },
    {
      id: 22,
      d: "M1455.988 521.135l-94.67-169.936 84.436-35.324 81.024 146.127z",
    },
    {
      id: 11,
      d:
        "M928.91 638.05l5.402-4.052 41.577-38.734 10.875-4.762-107.606-149.538-19.047 29.211-19.76 25.8-6.111 8.883z",
    },
    {
      id: 9,
      d:
        "M1027.63 526.607l20.397-27.222 38.877-29.21-68.797-131.2-14.997 8.882-24.52 5.402-21.11 8.174-14.286 2.061-8.172 1.99-7.463 4.123z",
    },
    {
      id: 7,
      d:
        "M1142.698 437.552l66.737-32.623-68.088-135.252-4.761 8.173-5.473 8.102-10.163 6.184-15.708 1.351-14.285 4.762-14.996-.71z",
    },
    {
      id: 5,
      d:
        "M1274.822 372.947l57.854-28.501 3.412-1.35-33.333-66.595-11.585-16.987-28.572-72.068-20.468-4.123-23.811-6.111-18.407 1.421-10.235 9.453z",
    },
    {
      id: 3,
      d:
        "M1396.712 317.936l63.327-26.51-89.908-210.022-15.637 64.606-6.822 17.626-8.812 13.575-2.773 2.06z",
    },
    {
      id: 1,
      d:
        "M1594.226 246.579l-41.578-103.341-.64-1.35-25.23-.64-13.647-4.761-32.623 24.449 44.207 103.98 3.412 7.462z",
    },
    {
      id: 25,
      d: "M1116.188 469.464l110.945 266.453 83.794-82.942L1197.21 429.38z",
    },
    {
      id: 32,
      d:
        "M1003.82 935.703l96.02-.638V976.5l-5.471 18.336-17.7 25.161-30.632-3.412-42.217.712z",
    },
    { id: 30, d: "M1004.46 842.599l96.02.71-.64 91.757-96.02.637z" },
    {
      id: 36,
      d: "M681.717 906.493l137.526-61.123 25.231 76.76-137.598 51.67z",
    },
    {
      id: 35,
      d:
        "M917.325 801.163l-82.444 36.674 21.819 68.017 147.76-63.255.932-92.83z",
    },
    {
      id: 38,
      d:
        "M951.5 1038.5L887.018 1049.56L925 1188L951.5 1188L957 1176.5L991.5 1176.5L951.5 1038.5Z",
    },
    {
      id: 39,
      d: "M1025 1035.5L951.5 1038.5L991.5 1176.5L1057.5 1160L1025 1035.5Z",
    },
    {
      id: 40,
      d: "M1108.5 1039L1025.5 1036L1057.5 1160L1129.5 1146L1108.5 1039Z",
    },
    {
      id: 41,
      d:
        "M1228.5 1052.5L1108 1038.5L1130 1145.5C1130 1145.5 1160.39 1127.08 1178 1132C1195.61 1136.92 1202.71 1148.67 1202.71 1148.67L1238 1141L1228.5 1052.5Z",
    },
  ],
}
