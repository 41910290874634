import React from "react"
import classNames from "classnames/bind"
import styles from "./styles.module.css"
import Img from "gatsby-image"
import ArrowLeft from "images/arrow-left.svg"
import { motion } from "framer-motion"
import Figure from "components/Figure"

const cx = classNames.bind(styles)

const fadeVariants = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
}

const variants = {
  in: {
    opacity: 1,
    scale: 1,
  },
  out: {
    opacity: 0,
    scale: 0.5,
  },
}

const Image = ({ isCurrent, image, id }) => {
  return (
    <div
      className={cx("imageWrap", { "-active": isCurrent })}
      aria-hidden={!isCurrent}
      tabIndex="-1"
    >
      {/* <Img
        aria-labelledby={id}
        className={cx("image")}
        fluid={image.fluid}
        imgStyle={{ objectFit: "contain" }}
      /> */}
      {/* <img className={cx("image")} src={image} alt="" /> */}
      <Figure node={image} objectFit="contain" />
    </div>
  )
}

const Lightbox = ({ images, initialImage, closeLightbox }) => {
  let [state, dispatch] = React.useReducer(
    (state, action) => {
      switch (action.type) {
        case "NEXT":
          return {
            ...state,
            currentIndex: (state.currentIndex + 1) % images.length,
          }
        case "PREV":
          return {
            ...state,
            currentIndex:
              (state.currentIndex - 1 + images.length) % images.length,
          }
        default:
          return state
      }
    },
    {
      currentIndex: initialImage,
    }
  )

  const handleClose = () => {
    closeLightbox()
  }

  const escFunction = React.useCallback(e => {
    if (e.keyCode === 27) {
      closeLightbox()
    } else if (e.keyCode === 37) {
      dispatch({ type: "PREV" })
    } else if (e.keyCode === 39) {
      dispatch({ type: "NEXT" })
    }
  }, [])

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false)

    return () => {
      document.removeEventListener("keydown", escFunction, false)
    }
  }, [])

  return (
    <motion.div
      className={cx("lightbox")}
      variants={fadeVariants}
      exit="out"
      animate="in"
      initial="out"
    >
      <div className={cx("shade")} onClick={handleClose} />

      <motion.div
        className={cx("images")}
        variants={variants}
        exit="out"
        animate="in"
        initial="out"
      >
        {images.map((image, index) => (
          <Image
            key={index}
            id={`image-${index}`}
            image={image}
            isCurrent={index === state.currentIndex}
          />
        ))}
      </motion.div>

      <div className={cx("controls")}>
        <button
          className={cx("control")}
          aria-label="Previous"
          onClick={() => {
            dispatch({ type: "PREV" })
          }}
        >
          <ArrowLeft className={cx("left")} />
        </button>
        <button
          className={cx("control")}
          aria-label="Next"
          onClick={() => {
            dispatch({ type: "NEXT" })
          }}
        >
          <ArrowLeft className={cx("right")} />
        </button>
        <button
          className={cx("close")}
          aria-label="Close"
          onClick={handleClose}
        >
          uždaryti
        </button>
      </div>
    </motion.div>
  )
}

export default Lightbox
