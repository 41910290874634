import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames/bind"
import styles from "./styles.module.css"

const cx = classNames.bind(styles)

const LandplotInfo = ({ forwardedRef, landplot }) => {
  const status = landplot.status ?? "Laisvas"

  return (
    <div className={cx("landplot-info")} ref={forwardedRef}>
      <h3 className={cx("info-title")}>
        {landplot.number === 21 || landplot.number === 25 ? "Vila" : "Sklypas"} nr. {landplot.number}

        <div className={cx("info-statusLabel", `-${status.substring(0, 3)}`)}>
          {status}
        </div>
      </h3>
      {!!landplot.area && (
        <div className={cx("info-area")}>
          <span className={cx("label")}>Plotas:</span>
          <span className={cx("value")}>{landplot.area} a.</span>
        </div>
      )}
      {!!landplot.price && status === "Laisvas" && (
        <div className={cx("info-price")}>
          <span className={cx("label")}>Kaina:</span>
          <span className={cx("value")}>€ {landplot.price}</span>
        </div>
      )}
    </div>
  )
}

LandplotInfo.propTypes = {}

LandplotInfo.defaultProps = {}

export default LandplotInfo
