import React from "react"
import { graphql } from "gatsby"
import Landplot from "components/Landplot"
import SEO from "components/Seo"
import { client, toPlainText } from "lib/helpers"

export const query = graphql`
  query globalSettings {
    sanityGlobalSettings {
      email
      phone
      address
      instagram
      facebook
    }
    allSanityLandplot(
      sort: { fields: [number], order: ASC }
      filter: { status: { ne: "Išjungtas" } }
    ) {
      edges {
        node {
          _id
          id
          number
          price
          area
          status
          mainImage {
            asset {
              id
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
          extraImages {
            _key
            asset {
              _id
              fluid(maxWidth: 1600) {
                ...GatsbySanityImageFluid
              }
              fixed(width: 1600) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
      }
    }
  }
`

const LandplotTemplate = props => {
  const { pageContext, errors, data } = props
  const landplotInitial = data.allSanityLandplot.edges
    .map(landplot => landplot.node)
    .find(landplot => landplot.id === pageContext.id)
  const [landplot, setLandplot] = React.useState(landplotInitial)

  const query = `*[_type=="landplot" && _id=="${pageContext._id}"][0] { _id, price, area, number, status, mainImage, extraImages }`

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await client.fetch(query)

        if (res && res.number) {
          setLandplot(res)
        }
      } catch (error) {
        console.error("err: ", error.message)
      }
    }

    fetchData()
  }, [query])

  return (
    <>
      {errors && <SEO title="GraphQL Error" />}
      {landplot && (
        <SEO
          title={`${landplot.number === 21 || landplot.number === 25 ? "Vila" : "Sklypas"} nr. ${landplot.number}` || "Bebrusų slėnis"}
          description={toPlainText(landplot._rawExcerpt)}
        />
      )}

      {/* {errors && <GraphQLErrorList errors={errors} />} */}

      {landplot && <Landplot {...landplot} data={data} />}
    </>
  )
}

export default LandplotTemplate
