import React from "react"
import classNames from "classnames/bind"
import styles from "./styles.module.css"
import { motion } from "framer-motion"
import Img from "gatsby-image"
import { Link } from "gatsby"

const cx = classNames.bind(styles)

const MoreInfo = ({ item }) => {
  const status = item.status ?? "Laisvas"
  return (
    <motion.div
      animate="open"
      exit="collapsed"
      initial="collapsed"
      variants={{
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 },
      }}
      transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
      className={cx("info")}
    >
      <div className={cx("info-inner")}>
        {item.mainImage && (
          <Img
            className={cx("info-image")}
            fluid={item.mainImage.asset.fluid}
          />
        )}
        <h3 className={cx("info-title")}>
          {item.number === 21 || item.number === 25 ? "Vila" : "Sklypas"} nr. {item.number}
          <div className={cx("info-statusLabel", `-${status.substring(0, 3)}`)}>
            {status}
          </div>
        </h3>
        {item.area && (
          <div className={cx("info-area")}>
            <span className={cx("label")}>Plotas:</span>
            <span className={cx("value")}>{item.area} a.</span>
          </div>
        )}
        {item.price && (
          <div className={cx("info-price")}>
            <span className={cx("label")}>Kaina:</span>
            <span className={cx("value")}>€ {item.price}</span>
          </div>
        )}

        <div className={cx("info-purpose")}>
          <span className={cx("label")}>Paskirtis:</span>
          <span className={cx("value")}>
          {item.number === 29 ? "Gyvenamoji" : item.number === 21 || item.number === 25 ? "Vila" : "Rekreacinė"}
          </span>
        </div>

        <Link className={cx("info-link")} to="/kontaktai">
          Teirautis
        </Link>
      </div>
    </motion.div>
  )
}

export default MoreInfo
