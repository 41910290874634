import React from "react"
import classNames from "classnames/bind"
import styles from "./styles.module.css"
import MoreInfo from "./MoreInfo"
import { AnimatePresence } from "framer-motion"

const cx = classNames.bind(styles)

const Item = ({ item, open, onClick }) => {
  const handleOnClick = id => {
    onClick(item.id)
  }

  const status = item.status ?? "Laisvas"

  return (
    <>
      <div
        className={cx("item")}
        data-number={item.number}
        id={`landplot-${item.number}`}
        onClick={handleOnClick}
      >
        <div className={cx("col", "-title")}>
          {item.number === 21 || item.number === 25 ? "Vila" : "Sklypas"} nr. {item.number}
        </div>
        <div className={cx("col", "-area")}>
          {item.area && <>{item.area} a.</>}
        </div>
        <div className={cx("col", "-price")}>
          {item.price && status === "Laisvas" && <>€ {item.price}</>}
        </div>
        <div className={cx("col", "-status")}>{status}</div>
      </div>
      <AnimatePresence initial={false}>
        {open && <MoreInfo layoutId="aa" item={item} />}
      </AnimatePresence>
    </>
  )
}

export default Item
