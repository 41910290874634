import React from "react"
import classNames from "classnames/bind"
import styles from "./styles.module.css"
import Areas from "./Map"
import sanityClient from "@sanity/client"
import { Link } from "gatsby"
import { isTablet } from "lib/helpers"
import { LandplotInfo } from "components/LandplotList"
import Figure from "components/Figure"
import Lightbox from "components/Lightbox"
import { AnimatePresence } from "framer-motion"
import Scroll from "images/scroll.svg"
import Fb from "images/contacts/fb.svg"
import In from "images/contacts/in.svg"

const cx = classNames.bind(styles)

const client = sanityClient({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: "production",
  useCdn: false,
})

function Landplot(props) {
  const {
    _id,
    price,
    area,
    number,
    status,
    mainImage,
    extraImages,
    data,
  } = props
  const [lightbox, setLightbox] = React.useState(null)

  const landplotsInitial = data.allSanityLandplot.edges.map(
    landplot => landplot.node
  )

  const [landplots, setLandplots] = React.useState(landplotsInitial)

  let image
  if (!mainImage) {
    image = null
  } else if (mainImage.asset.hasOwnProperty("_ref")) {
    image = mainImage
  } else if (mainImage.asset.hasOwnProperty("id")) {
    image = { asset: { _ref: mainImage.asset.id } }
  }

  const tmpImages = [image]
  const merged = tmpImages.concat(extraImages)

  const [imgs, setImgs] = React.useState(merged)
  // console.log(landplotsInitial)

  const init = React.useRef(false)
  const infoEl = React.useRef(null)
  const hasLandplotSet = React.useRef(false)
  const areasRef = React.useRef()
  const [showInfo, setShowInfo] = React.useState(false)
  const [currentLandplotId, setCurrentLandplotId] = React.useState(null)
  const [currentLandplot, setCurrentLandplot] = React.useState(null)

  const query =
    '*[_type=="landplot" && status != "Išjungtas"] | order(number asc) { id, price, area, number, status, mainImage, extraImages}'

  const imagesQuery = `*[_type=="landplot" && status != "Išjungtas" && _id =="${_id}"] { extraImages }`

  React.useEffect(() => {
    const areasElRect = areasRef.current.getBoundingClientRect()
    areasRef.current.scrollLeft = (areasElRect.width / 2) * -1

    const fetchData = async () => {
      try {
        const res = await client.fetch(query)
        const imgsRes = await client.fetch(imagesQuery)

        if (res && res.length) {
          console.log(res)
          setLandplots(res)
        }

        if (imgsRes && imgsRes.length) {
          const tmpImages = [image]
          const merged = tmpImages.concat(imgsRes[0].extraImages)
          setImgs(merged)
          console.log(merged, imgsRes)
        }
      } catch (error) {
        console.error("err: ", error.message)
      }
    }

    fetchData()

    init.current = true
  }, [])

  React.useEffect(() => {
    if (!hasLandplotSet.current) {
      hasLandplotSet.current = true
      return
    }

    const landplot = landplots.find(
      landplot => landplot.number === currentLandplotId
    )

    if (landplot) {
      setCurrentLandplot(landplot)
    }
  }, [currentLandplotId])

  const handleMouseEnter = e => {
    setShowInfo(true)
  }

  const handleMouseMove = e => {
    const offset = 5
    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth
    let el
    let x = e.clientX + offset
    let y = e.clientY + offset

    if (showInfo && infoEl.current) {
      el = infoEl.current.getBoundingClientRect()

      if (x + el.width >= windowWidth) {
        x = windowWidth - el.width
      }

      infoEl.current.style.transform = `translateX(${x}px) translateY(${y}px)`
    }
  }

  const handleMouseLeave = e => {
    setShowInfo(false)
  }

  const getLandplot = id => {
    if (id) {
      setCurrentLandplotId(+id)
    }
  }

  const handleOnClick = index => {
    setLightbox(index)
  }

  const handleClose = () => {
    setLightbox(null)
  }

  const statusString = status ?? "Laisvas"

  return (
    <>
      <div className={cx("wrapper")}>
        <div className={cx("content")}>
          <Link className={cx("back")} to="/sklypai">
            Grįžti
          </Link>
          <h1 className={cx("info-title")}>
            {number === 21 || number === 25 ? "Vila" : "Sklypas"} nr. {number}
            <div
              className={cx(
                "info-statusLabel",
                `-${statusString.substring(0, 3)}`
              )}
            >
              {statusString}
            </div>
          </h1>
          {area && (
            <div className={cx("info-area")}>
              <span className={cx("label")}>Plotas:</span>
              <span className={cx("value")}>{area} a.</span>
            </div>
          )}
          {price &&
            (status === "Laisvas" ||
              status === null ||
              status === undefined) && (
              <div className={cx("info-price")}>
                <span className={cx("label")}>Kaina:</span>
                <span className={cx("value")}>€ {price}</span>
              </div>
            )}

          <div className={cx("info-purpose")}>
            <span className={cx("label")}>Paskirtis:</span>
            <span className={cx("value")}>
              {number === 29
                ? "Gyvenamoji"
                : number === 21 || number === 25
                ? "Vila"
                : "Rekreacinė"}
            </span>
          </div>

          <Link className={cx("info-link")} to="/kontaktai">
            Teirautis
          </Link>

          {/* {image && (
            <div
              className={cx("landplot-image")}
              onClick={() => handleOnClick()}
            >
              <Figure node={image} maxWidth={400} />
            </div>
          )} */}
          <div className={cx("landplot-images")}>
            {imgs.map((image, index) => {
              if (!image) return
              return (
                <div
                  key={`gi-${index}`}
                  className={cx("landplot-image")}
                  onClick={() => handleOnClick(index)}
                >
                  <Figure node={image} maxWidth={400} />
                </div>
              )
            })}
          </div>

          <div className={cx("contacts")}>
            {data.sanityGlobalSettings?.email && (
              <div className={cx("contacts-itemWrap")}>
                <div className={cx("contacts-itemLabel")}>El. paštas:</div>
                <a
                  href={`mailto:${data.sanityGlobalSettings?.email}`}
                  target="_blank"
                  className={cx("contacts-item")}
                >
                  {data.sanityGlobalSettings.email}
                </a>
              </div>
            )}
            {data.sanityGlobalSettings?.phone && (
              <div className={cx("contacts-itemWrap")}>
                <div className={cx("contacts-itemLabel")}>
                  Telefono numeris:
                </div>
                <a
                  href={`tel:${data.sanityGlobalSettings?.phone}`}
                  target="_blank"
                  className={cx("contacts-item")}
                >
                  {data.sanityGlobalSettings.phone}
                </a>
              </div>
            )}
            {data.sanityGlobalSettings.address && (
              <div className={cx("contacts-itemWrap")}>
                <div className={cx("contacts-itemLabel")}>Adresas:</div>
                <span className={cx("contacts-item")}>
                  {data.sanityGlobalSettings.address}
                </span>
              </div>
            )}
            <div className={cx("contacts-itemWrap", "-social")}>
              {data.sanityGlobalSettings.facebook && (
                <a
                  href={`${data.sanityGlobalSettings.facebook}`}
                  target="_blank"
                  className={cx("contacts-item")}
                >
                  <Fb />
                </a>
              )}
              {data.sanityGlobalSettings.instagram && (
                <a
                  href={`${data.sanityGlobalSettings.instagram}`}
                  target="_blank"
                  className={cx("contacts-item")}
                >
                  <In />
                </a>
              )}
            </div>
          </div>
        </div>
        <div className={cx("scroll-wrapper")}>
          <Scroll className={cx("scroll")} />
        </div>
        <div className={cx("landplots-wrap")} ref={areasRef}>
          <div className={cx("landplots-inner")}>
            <img
              src="/images/back-sitas.jpg"
              className={cx("areas-background")}
              alt="map background"
            />
            <Areas
              landplots={landplots}
              mouseEnter={handleMouseEnter}
              mouseMove={handleMouseMove}
              mouseLeave={handleMouseLeave}
              setLandplot={getLandplot}
              active={number}
              className={cx("landplots")}
            />
          </div>
        </div>
        {showInfo && currentLandplot && !isTablet() && (
          <LandplotInfo forwardedRef={infoEl} landplot={currentLandplot} />
        )}
      </div>
      <AnimatePresence>
        {lightbox !== null && (
          <Lightbox
            images={imgs}
            initialImage={lightbox}
            closeLightbox={handleClose}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default Landplot
