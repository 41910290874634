import React from "react"
import classNames from "classnames/bind"
import { motion } from "framer-motion"
import styles from "./styles.module.css"
import PageHeader from "components/PageHeader"
import { navigate } from "gatsby"
import Item from "./Item"

const cx = classNames.bind(styles)

const LandplotList = ({ landplots, handleOpenedItem, opened }) => {
  const openItem = id => {
    navigate(`/sklypai/nr-${id}`)
    // handleOpenedItem(id)
  }

  return (
    <div className={cx("wrapper")}>
      <PageHeader className={cx("header")}>Sklypų sąrašas</PageHeader>
      <motion.div className={cx("list")} layout>
        {landplots.length &&
          landplots.filter(item=>item.status !== "Parduotas" && item.status !== "Rezervuotas").map(item => {
            const id = item.number
            return (
              <Item
                item={item}
                key={id}
                onClick={() => openItem(id)}
                open={id === opened}
              />
            )
          })}
      </motion.div>
    </div>
  )
}

export default LandplotList
